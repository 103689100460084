import React from 'react'
import {Link} from 'gatsby'
import PortableText from '../../serializers/portableText'
import styles from './job.module.css'
import LeftChevron from '../../../images/icons/left-chevron.svg'

const Jobs = ({PositionSummary, Responsibilities, MinimumQualifications, PreferredQualifications, AboutCompany, jobAppLink, id, slug}) => {
  return (
    <>
      <div className={styles.jobWrapper}>

        <div className={styles.btnWrapper}>
          <Link to={`/careers`} className={styles.moreinfoBtn}><span><LeftChevron /> Back to Jobs</span></Link>

          <Link to={jobAppLink || `https://lindgrenlandscaping.applytojob.com/apply`} className={styles.applyBtn}><span>Apply Here</span></Link>
        </div>

        {PositionSummary && (<div> <PortableText blocks={PositionSummary} /> </div>)}

        {Responsibilities && (<div><h3>Responsibilities</h3> <PortableText blocks={Responsibilities} /> </div>)}

        {MinimumQualifications && (<div><h3>Minimum Qualifications</h3> <PortableText blocks={MinimumQualifications} /> </div>)}

        {PreferredQualifications && (<div><h3>Preferred Qualifications</h3> <PortableText blocks={PreferredQualifications} /> </div>)}

        {AboutCompany && (<div><h3>About Company</h3> <PortableText blocks={AboutCompany} /> </div>)}

      </div>
    </>

  )
}
export default Jobs
